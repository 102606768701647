/**********************************************************
/ NAME: RegisterCompany()
/********************************************************** 
 * SUMMARY: 
 * RegisterCompany lets the user register a company and 
 * save it in the database. 
 * 
 * /********************************************************** 
 * INPUT: 
 * registerNumber: The registration number of the company
 * contactEmail: The contact email of the company
 * AccountantEmail: The accountant email of the company
 * phoneNumber: The phone number of the company
 * companyAdress: The company adress of the company
 * name: The name of the company
 * insuranceFile: The insurance file of the company
 * EnvironmentalFile: The environmental file of the company
 * expiryDate: The expiry date of the company insurance
 * collaborators: The collaborators of the company
 * 
/********************************************************** 
 * FUNCTIONS:
 * getInformation(): Gets the information about the company
 * storeFile(): Stores the file in the database
 * saveCargoOwner(): Saves the cargo owner in the database
 * saveCarrier(): Saves the carrier in the database
 * addCollaborators(): Adds the collaborators to the database
 * getUser(): Gets the user information from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * 
/*********************************************************/

import {
  Input,
  Box,
  Button,
  Text,
  SimpleGrid,
  FormControl,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertDescription,
  VStack,
  Image,
} from "@chakra-ui/react";
import { SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getCollaboratorNames,
  getCompanyInformation,
  saveCargoOwner,
  saveCarrier,
} from "../databasefunctions/company";
import { findInfo } from "../databasefunctions/storeandfind";
import logo from "../../assets/logo.png";
// import { Tooltip } from "@mui/material";

import {
  AddCollaborators,
  UpdateInsurance,
  storeFile,
} from "../databasefunctions/users";
import { Formik } from "formik";
import { ResetButton, SubmitButton } from "formik-chakra-ui";
import Createable from "../layout/Createable";
import LoginLayout from "../layout/LoginLayout";
interface UserState {
  userCarrier: boolean;
  userCargoOwner: boolean;
}
const initialValues = {
  registrationNumber: "",
  contactEmail: "",
  AccountantEmail: "",
  phoneNumber: "",
  companyAdress: "",
  name: "",
  insuranceFile: "",
  environmentalCertificateFile: "",
  otherFile: "",
  expiryDate: "",
};
interface Option {
  label: string;
  value: string;
}

function RegisterCompany() {
  const { state } = useLocation();
  const { userCarrier, userCargoOwner } = state as UserState;
  const [uCarrier] = useState(userCarrier);
  const [uCaroOwner] = useState(userCargoOwner);
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [AccountantEmail, setAccountantEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyAdress, setCompanyAdress] = useState("");
  const [name, setCompanyName] = useState("");
  const [collaboratorName, setCollaboratorName] = useState<any>(null);
  const [insuranceFile, setInsuranceFile] = useState<File>();
  const [ecFile, setFileEC] = useState<File>();
  const [collaborators, setCollaborators] = useState<Option[]>([]);
  const navigate = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  let isError = false;
  const getInformation = async () => {
    if (registrationNumber !== "") {
      await getCompanyInformation(registrationNumber)
        .then(() => {
          isError = false;
        })
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError)
        });
    } else {
      isError = true;
      setErrorMessage("Skriv inn org. nummer her");
    }
    if (isError === true) {
      setHasError(true);
    } else setHasError(false);
    let companyName = findInfo("companyName");

    let companyAdressInput = await findInfo("companyAddress");
    setCompanyAdress(companyAdressInput);

    //console.log(companyName);
    setCompanyName(`${companyName}`);
  };

  const onSubmit = async (values: any) => {
    //console.log("Hei")
    //console.log(values.name)
    if (values.expiryDate.search("T") === -1) {
      values.expiryDate = values.expiryDate + "T00:00:00.0";
    }
    if (ecFile) {
      await storeFile(ecFile, "Environmental certificate", false);
    }
    if (uCarrier) {
      if (!insuranceFile) return;
      await storeFile(insuranceFile, "Liability insurance", true);
    }

    if (uCarrier === true) {
      await saveCarrier(
        registrationNumber,
        AccountantEmail,
        companyAdress,
        name
      );
    }
    if (uCaroOwner === true) {
      await saveCargoOwner(
        registrationNumber,
        AccountantEmail,
        companyAdress,
        name
      );
    }
    let collabs: any[] = [];
    collaborators.forEach((element: any) => {
      collabs.push(`${element.value}`);
    });
    AddCollaborators(collabs);
    await UpdateInsurance(true, values.expiryDate)
      .then(async () => {
        await new Promise((f) => setTimeout(f, 3000));
      })
      .then(() => {
        navigate("/dashboard");
      });
  };
  //console.log(uCaroOwner,uCarrier)
  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setRegistrationNumber(e.target.value);
    setFieldValue("registrationNumber", e.target.value);
  };
  function handleReset() {
    setRegistrationNumber("");
    setCompanyName("");
    setCompanyAdress("");
    setContactEmail("");
    setAccountantEmail("");
    setPhoneNumber("");
  }
  const handleFileUploadInsurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInsuranceFile(event.target.files![0]);
  };
  const handleFileUploadEC = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileEC(event.target.files![0]);
  };
  const getCollabNames = async (): Promise<void> => {
    let collabNames: any[] = [];
    const promises = collaborators.map((element: any) =>
      getCollaboratorNames(element.value)
    );
    const resolvedNames = await Promise.all(promises);
    collabNames = resolvedNames;
    //console.log(collabNames)
    setCollaboratorName(collabNames);
  };
  let collaboratorNameString = <Text></Text>;
  if (collaboratorName) {
    collaboratorNameString = <Text>{collaboratorName}</Text>;
  }

  return (
    <LoginLayout>
      <VStack maxW="md" gap={4}>
        <Image src={logo} h={200} />
        {hasError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          //validationSchema={validationSchema}
          onReset={handleReset}
        >
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              {/* <Text fontSize="2xl">Truck</Text> */}
              <Box
                borderWidth="2px"
                rounded="lg"
                borderColor="blackAlpha.400"
                shadow="2px 2px 4px rgba(0,0,0,0.3)"
                width="80vw"
                maxWidth="500"
                minWidth="30vw"
                p={6}
                onSubmit={handleSubmit as any}
                m="10px auto"
                as="form"
              >
                <Text variant="label">Orgnr</Text>
                {hasError && (
                  <Alert status="error" mt="4" mb="-4">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    name="registrationNumer"
                    value={registrationNumber}
                    placeholder="Orgnr"
                    onChange={handleChange}
                  />
                </FormControl>

                <Button
                  variant="verify"
                  onClick={getInformation}
                  sx={{ m: 1 }}
                  margin="10px"
                >
                  Bekrefte
                </Button>

                <Text variant="label">Navn</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={name}
                    placeholder="Navn"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Adresse</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={companyAdress}
                    placeholder="Adresse"
                    onChange={(e) => setCompanyAdress(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Firma E-post</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    type="email"
                    value={contactEmail}
                    placeholder="E-post"
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Regnskapsfører E-post</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={AccountantEmail}
                    placeholder="E-post"
                    type="email"
                    onChange={(e) => setAccountantEmail(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Firma telefonnummer</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={phoneNumber}
                    placeholder="telefonnummer"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="tel"
                  />
                </FormControl>
                <SimpleGrid columns={2} columnGap="2">
                  <Text variant="label">Forsikringsbevis</Text>
                  <Text variant="label">Miljøsertifikat</Text>

                  <FormControl isRequired>
                    <Input
                      variant="standardTextInput"
                      type="file"
                      name="file"
                      accept=".pdf"
                      onChange={handleFileUploadInsurance}
                    ></Input>
                  </FormControl>

                  <Input
                    variant="standardTextInput"
                    type="file"
                    name="file"
                    onChange={handleFileUploadEC}
                  ></Input>
                </SimpleGrid>
                <Text variant="label">Utløpsdato på Forsikringsbevis</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    type="date"
                    name="expiryDate"
                    onChange={(e) => (values.expiryDate = e.target.value)}
                  />
                </FormControl>
                {/* <Text variant="label" >Annet</Text>
          <Box borderWidth="1px"
                  rounded="lg"
                  shadow="1px 1px 3px rgba(0,0,0,0.3)"
                  mt="4"
                  mb="4"
                  p={2}>      
          <input type="file" name="file" onChange={(e)=>setOtherFile(e.target.files)} accept="image/*,.pdf"></input>
          </Box> */}
                <Text mb="-2" variant="label">
                  Org. nummer på samarbeidspartnere
                </Text>
                <Createable
                  setCreateableValue={setCollaborators}
                  CreateableValue={collaborators}
                  options={collaborators}
                />
                <Button
                  variant="verify"
                  onClick={getCollabNames}
                  sx={{ m: 1 }}
                  margin="10px"
                >
                  Valider
                </Button>
                {collaboratorNameString}
                <ButtonGroup mt={4}>
                  <SubmitButton variant="primary">Lagre</SubmitButton>
                  <ResetButton>Tilbakestill</ResetButton>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Formik>
      </VStack>
    </LoginLayout>
  );
}

export default RegisterCompany;
function setFieldValue(arg0: string, value: any) {
  throw new Error("Function not implemented.");
}
