/**********************************************************
/ NAME: CargoOverview()
/********************************************************** 
 * SUMMARY: 
 * CargoOverview fetches all the cargos the user has registered
 * and populates a list using the CargoRow component
 * 
/********************************************************** 
 * INPUT: 
 * No Inputs
 * 
/********************************************************** 
 * FUNCTIONS:
 * getCargos(): Fetches all the cargos from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * CargoRow: The component that displays the information about the cargo
/*********************************************************/
import {
  Box,
  Button,
  Grid,
  Heading,
  Divider,
  Center,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getCargos } from "../databasefunctions/cargos";
import { auth } from "../../firestore/firebaseConfig";
import OneColumnLayout from "../layout/OneColLayout";
import CargoRow from "./CargoRow";
import { BrowserView, MobileView } from "react-device-detect";
import { MdKeyboardReturn } from "react-icons/md";
export default function CargoOverview() {
  const [cargos, setCargo] = useState<any>(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!cargos) {
      getCargos()?.then((p) => setCargo(p));
      //console.log(cargos)
    }
  }, [cargos, user]);

  return (
    <OneColumnLayout
      sectionLabel="All freight purchases"
      sectionHeading="Mine Gods"
      sectionIntro=""
      content={
        <>
          <BrowserView>
            <Grid
              mb={2}
              templateColumns="6vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 8vw"
              gap={2}
            >
              <Box></Box>
              <Box alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}>Fra</Heading>
              </Box>
              <Box alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> Avsendings dato</Heading>
              </Box>
              <Box alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> Til</Heading>
              </Box>
              <Box alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> Dimensjoner [LxBxH]</Heading>
              </Box>
              <Box alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> Vekt[kg]</Heading>
              </Box>
              <Box alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> Status</Heading>
              </Box>
            </Grid>
            <Divider variant="thick" mt="1" mb="1" width={"90vw"}></Divider>
            {cargos?.map((cargo: any) => (
              <CargoRow key={cargo.id} cargo={cargo} />
            ))}
            <Button variant="primary" as="a" href="/create-cargo">
              Registrer nytt gods
            </Button>
            <Stack mt="4" direction="row" spacing={4} align="center">
              <Button
                leftIcon={<MdKeyboardReturn />}
                variant="return"
                as="a"
                href="/dashboard"
              >
                Tilbake
              </Button>
            </Stack>
          </BrowserView>
          <MobileView>
            <Grid mb={2} templateColumns="18vw 18vw 18vw 25vw" gap={2}>
              <Center alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> </Heading>
              </Center>
              <Center alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}>Fra</Heading>
              </Center>
              <Center alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> Dato</Heading>
              </Center>

              <Center alignSelf={"center"}>
                <Heading fontSize={"1.2rem"}> Status</Heading>
              </Center>
            </Grid>
            <Divider
              variant="thick"
              mt="1"
              mb="1"
              ml="-2"
              width={"85vw"}
            ></Divider>
            {cargos?.map((cargo: any) => (
              <CargoRow key={cargo.id} cargo={cargo} />
            ))}
            <Center>
              <Button variant="primary" as="a" href="/create-cargo">
                Registrer nytt gods
              </Button>
            </Center>

            <Stack direction="row" spacing={4} align="center" mt="4">
              <Button
                leftIcon={<MdKeyboardReturn />}
                variant="return"
                as="a"
                href="/dashboard"
              >
                Tilbake
              </Button>
            </Stack>
          </MobileView>
        </>
      }
    />
  );
}
